<template>
  <div class="leading-8 md:leading-6 text-justify mb-6 font-nunito-sans">
    <div class="pb-6">
      <div class="flex w-full border-b-2 border-color-gray-400">
        <div class="flex-auto text-left font-bold">404 Not found!</div>
      </div>
      <div
        class="mt-4 mb-2 md:-mt-10 flex justify-center md:justify-end flex-wrap"
      >
        <div class="sad-face"></div>
      </div>
      <div class="mt-0 2xl:-mt-20 pr-6 2xl:pr-36">
        Sorry, but the page you were looking for was not found. If this has
        happened by mistake, please report the broken link by contacting me on
        Twitter. Thanks!
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "error-404",
});
</script>
<style scoped>
.sad-face {
  margin: 20px;
  position: relative;
  background: #ffe6e6;
  height: 100px;
  width: 100px;
  border: 2px solid #9e9e9e;
  border-radius: 50%;
}
.sad-face::after {
  position: absolute;
  content: ". .";
  font-size: 50px;
  text-align: center;
  color: #9e9e9e;
  width: 50px;
  top: 10px;
  left: 22px;
  animation-name: face-eyes;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}
.sad-face::before {
  position: absolute;
  content: "";
  border-radius: 0;
  bottom: 35px;
  right: 25px;
  width: 35px;
  height: 60px;
  border: 2px solid transparent;
  border-bottom-color: #9e9e9e;
  animation-name: face-mouth;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}
@keyframes face-mouth {
  0% {
    right: 25px;
    width: 35px;
  }
  10% {
    right: 23px;
    width: 30px;
  }
  20% {
    right: 22px;
    width: 25px;
  }
  30% {
    right: 23px;
    width: 30px;
  }
  40% {
    right: 25px;
    width: 35px;
  }
}
@keyframes face-eyes {
  0% {
    font-size: 50px;
    top: 10px;
    left: 22px;
  }
  10% {
    font-size: 45px;
    top: 6px;
    left: 20px;
  }
  20% {
    font-size: 40px;
    top: 3px;
    left: 16px;
  }
  30% {
    font-size: 45px;
    top: 6px;
    left: 20px;
  }
  40% {
    font-size: 50px;
    top: 10px;
    left: 22px;
  }
}
</style>
