<template>
  <h2 class="text-2xl font-bold mb-4">Opt Out</h2>
  <div class="leading-8 md:leading-6 text-justify mb-6 font-nunito-sans">
    You have successfully opt out from cookie consent.
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  methods: {
    rejectCookie() {
      this.$store.dispatch("rejectCookieConsent");
    },
  },
  mounted() {
    this.rejectCookie();
  },
})
export default class OptOut extends Vue {}
</script>
