<template>
  <h2 class="text-2xl font-bold mb-4">Cookie policy</h2>
  <div class="leading-8 md:leading-6 text-justify mb-6 font-nunito-sans">
    <h2 class="text-xl font-bold pt-4 pb-2">Last updated: Jun 10, 2022</h2>
    My personal portfolio uses cookies, mobile identifiers, tracking URLs, log
    data and similar technologies to help provide, protect, and improve this
    website.

    <h2 class="text-xl font-bold pt-4 pb-2">Why I Use These Technologies</h2>
    We use these technologies for a number of purposes, such as:
    <ul class="list-disc list-outside pt-4">
      <li class="pl-0 pb-4 md:ml-8">
        To enable you to use and access to this website.
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        To enable, facilitate and streamline the functioning of and your access
        to this website.
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        To better understand how you navigate through and interact with this
        website, to improve your experience.
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        To show you content (e.g., articles, advertisements) that is more
        relevant to you.
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        To monitor and analyze the performance, operation, and effectiveness of
        advertisements.
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        To enforce legal agreements that govern use of this website.
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        For fraud detection and prevention, trust and safety, and
        investigations.
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        For purposes of our own customer support, analytics, research, product
        development, and regulatory compliance.
      </li>
    </ul>

    <h2 class="text-xl font-bold pt-4 pb-2">Cookies</h2>
    When you visit this website we may place cookies on your device. Cookies are
    small text files that websites send to your computer or other
    Internet-connected device to uniquely identify your browser or to store
    information or settings in your browser. Cookies allow us to recognize you
    when you return. They also help us provide a customized experience and can
    enable us to detect certain kinds of fraud. In many cases the information we
    collect using cookies and other tools is only used in a non-identifiable
    manner without reference to personal information. For example, we may use
    information we collect to better understand website traffic patterns and to
    optimize our website experience. In some cases we associate the information
    we collect using cookies and other technology with your personal
    information. Our business partners may also use these tracking technologies
    on this website or engage others to track your behavior on our behalf.

    <h2 class="text-xl font-bold pt-4 pb-2">
      Server Logs and Other Technologies
    </h2>
    We collect many different types of information from server logs and other
    technologies. For example, we collect information about the device you use
    to access this website, your operating system type, browser type, domain,
    and other system settings, as well as the language your system uses and the
    country and time zone where your device is located. Our server logs also
    record the IP address of the device you use to connect to the Internet. An
    IP address is a unique identifier that devices require to identify and
    communicate with each other on the Internet. We may also collect information
    about the website you were visiting before you came to this website and the
    website you visit after you leave this website. We may also collect
    information about your use of this website. The tools described help us
    improve user experience and deliver our services.

    <h2 class="text-xl font-bold pt-4 pb-2">Your Choices</h2>
    Most browsers automatically accept cookies, but you can modify your browser
    setting to decline cookies by visiting the Help portion of your browser’s
    toolbar. While you may disable cookies through your browser settings, this
    website currently does not respond to a “Do Not Track” signal in the HTTP
    header from your browser or mobile application due to lack of
    standardization regarding how that signal should be interpreted.

    <br /><br />Your mobile device may allow you to control cookies through its
    settings function. Refer to your device manufacturer’s instructions for more
    information.

    <br /><br />If you choose to decline cookies, some parts of this website may
    not work as intended or may not work at all.
  </div>
</template>
