
import { Options, Vue } from "vue-class-component";

@Options({
  methods: {
    rejectCookie() {
      this.$store.dispatch("rejectCookieConsent");
    },
  },
  mounted() {
    this.rejectCookie();
  },
})
export default class OptOut extends Vue {}
