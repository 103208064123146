<template>
  <h2 class="text-2xl font-bold mb-4">Privacy policy</h2>
  <div class="leading-8 md:leading-6 text-justify mb-6 font-nunito-sans">
    <h2 class="text-xl font-bold pt-4 pb-2">Last updated: Jun 10, 2022</h2>
    My personal portfolio exists to connect people with me either on the
    business-level or to share my views and experiences in technical and
    non-technical articles.
    <br />This Privacy Policy describes how Seyed Pooyan Razian and its partners
    (“we,” “us,” or “Pooyan”), process personal information in relation to your
    use of my personal portfolio.

    <h2 class="text-xl font-bold pt-4 pb-2">1. Definitions</h2>
    Undefined terms in this Privacy Policy have the same definition as in the
    <a href="/terms">terms of service ("terms")</a>

    <h2 class="text-xl font-bold pt-4 pb-2">
      2. Personal information we collect
    </h2>
    When you use this website, we collect your <b>IP address</b> and we store it
    in our databases for one year. Your IP address may also be used by other
    solutions we use on AWS. Without it, we may not be able to provide
    <b>secure</b> and <b>reliable</b> service to you.

    <br /><br />
    You cannot request to delete this data as it cannot be mapped to your IP
    address.

    <h2 class="text-xl font-bold pt-4 pb-2">
      2.2 Information you choose to give us.
    </h2>
    You can choose to provide us with additional personal information. This
    information may include:
    <ul class="list-disc list-outside pt-4">
      <li class="pl-0 pb-4 md:ml-8">
        <b>Additional Information.</b> Such as when you fill in the contact form
        if you choose to share it with us.
      </li>
    </ul>

    <h2 class="text-xl font-bold pt-4 pb-2">
      2.3 Information Automatically Collected by Using this website.
    </h2>
    When you use my personal portfolio, we automatically collect personal
    information. This information may include:
    <ul class="list-disc list-outside pt-4">
      <li class="pl-0 pb-4 md:ml-8">
        <b>Geo-location Information.</b> Such as precise or approximate location
        determined from your IP address or mobile device’s GPS depending on your
        device settings. We may also collect this information when you’re not
        using the web app.
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        <b>Cookies and Similar Technologies</b> We only collect cookies when you
        agree with the cookie policy. When you accept the terms, your data might
        be shared with Google. This is needed if you want to send a message
        through the contact form, because we use Google reCAPTCHA to prevent
        spam and we need your consent before sharing your data with a
        third-party service.
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts"></script>
