<template>
  <h2 class="text-2xl font-bold mb-4">Terms and conditions of use</h2>
  <div class="leading-8 md:leading-6 text-justify mb-6 font-nunito-sans">
    <h2 class="text-xl font-bold pt-4 pb-2">1. Terms</h2>
    By accessing this website, accessible from pooyan.info, you are agreeing to
    be bound by these Terms and Conditions of Use and agree that you are
    responsible for the agreement with any applicable local laws. If you
    disagree with any of these terms, you are prohibited from accessing this
    site. The materials contained in this Website are protected by copyright and
    trade mark law, and you are not allowed to use them without my permission.

    <h2 class="text-xl font-bold pt-4 pb-2">2. Use License</h2>
    Permission is granted to temporarily download one copy of the materials on
    my personal portfolio for personal, non-commercial transitory viewing only.
    This is the grant of a license, not a transfer of title, and under this
    license you may not:
    <ul class="list-disc list-outside pt-4">
      <li class="pl-0 pb-4 md:ml-8">modify or copy the materials;</li>
      <li class="pl-0 pb-4 md:ml-8">
        Use the materials for any commercial purpose or for any public display;
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        Attempt to reverse engineer any software contained on my personal
        portfolio;
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        Remove any copyright or other proprietary notations from the materials;
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        Transferring the materials to another person or "mirror" the materials
        on any other server.
      </li>
    </ul>
    This will let the owner, Seyed Pooyan Razian, to terminate upon violations
    of any of these restrictions. Upon termination, your viewing right will also
    be terminated and you should destroy any downloaded materials in your
    possession whether it is printed or electronic format.

    <h2 class="text-xl font-bold pt-4 pb-2">3. Disclaimer</h2>
    All the materials on my personal portfolio are provided “as is”. I make no
    warranties, may it be expressed or implied, therefore negates all other
    warranties. Furthermore, I may not make any representations concerning the
    accuracy or reliability of the use of the materials on its Website or
    otherwise relating to such materials or any sites linked to this Website.

    <br /><br />The information provided by me here or on any social media is
    general in the nature and is not intended to be any sort of legal advice.

    <br /><br />I try to offer unbiased information to the best of my ability,
    but as a a human I can have unconscious biases shaping my thoughts. You are
    more than welcome to remind me of them if you see so, but please read my
    articles only if you know you will not take anything personal.

    <br /><br />In addition, views are my own not any of my past or current
    employers'.

    <h2 class="text-xl font-bold pt-4 pb-2">4. Limitations</h2>
    The owner, Seyed Pooyan Razian, will not be hold accountable for any damages
    that will arise with the use or inability to use the materials on my
    personal portfolio, even if I or an authorize representative of this website
    has been notified, orally or written, of the possibility of such damage.
    Some jurisdiction does not allow limitations on implied warranties or
    limitations of liability for incidental damages, these limitations may not
    apply to you.

    <h2 class="text-xl font-bold pt-4 pb-2">5. Revisions and Errata</h2>
    The materials appearing on my persona portfolio may include technical,
    typographical, or photographic errors. I will not promise that any of the
    materials in this Website are accurate, complete, or current. I may change
    the materials contained on its Website at any time without notice. I also do
    not make any commitment to update the materials.

    <h2 class="text-xl font-bold pt-4 pb-2">6. Links</h2>
    I have not reviewed all of the sites linked to its Website and I am not
    responsible for the contents of any such linked site. The presence of any
    link does not imply endorsement by me of the site. The use of any linked
    website is at the user's own risk.

    <h2 class="text-xl font-bold pt-4 pb-2">
      7. Site Terms of Use Modifications
    </h2>
    The owner, Seyed Pooyan Razian, may revise these Terms of Use for its
    Website at any time without prior notice. By using this Website, you are
    agreeing to be bound by the current version of these Terms and Conditions of
    Use.

    <h2 class="text-xl font-bold pt-4 pb-2">8. Copyright</h2>
    I respect the intellectual property rights of others, and I will respond to
    notices of alleged copyright infringement that comply with applicable law
    and are properly provided to me.

    <br /><br />In general, I will never use photos or videos with a copyright
    watermark on them without prior permission from the owner. For the memes I
    use, I will use the ones without copyright watermark and I will also put a
    link to the source to be fair, but in most scenarios it might not be
    possible for me to find the real owner of the image. In that case, please
    contact me if you are the owner of the image and you do not want me to put
    it on my website.

    <br /><br />If you believe that your content has been copied in a way that
    constitutes copyright infringement, please provide me with the following
    information, and then I will either remove the content or will put a link to
    your website based on your preference:

    <ul class="list-disc list-outside pt-4">
      <li class="pl-0 pb-4 md:ml-8">
        A physical or electronic signature of the copyright owner or a person
        authorized to act on their behalf;
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        Identification of the copyrighted work claimed to have been infringed;
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        Identification of the material that is claimed to be infringing or to be
        the subject of infringing activity and that is to be removed or access
        to which is to be disabled, and information reasonably sufficient to
        permit me to locate the material;
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        Your contact information, including your address, telephone number, and
        an email address;
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        A statement by you that you have a good faith belief that use of the
        material in the manner complained of is not authorized by the copyright
        owner, its agent, or the law;
      </li>
      <li class="pl-0 pb-4 md:ml-8">
        A statement that the information in the notification is accurate, and,
        under penalty of perjury, that you are authorized to act on behalf of
        the copyright owner.
      </li>
    </ul>

    <h2 class="text-xl font-bold pt-4 pb-2">9. Governing Law</h2>
    Any claim related to the materials on my personal portfolio shall be
    governed by the laws of country I reside in (currently Denmark), without
    regards to its conflict of law provisions.
  </div>
</template>
