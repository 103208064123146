<template>
  <h1 class="text-2xl font-bold mb-4">Contact me</h1>
  <ul class="list-disc list-outside pt-4">
    <li class="pl-0 pb-4 md:ml-8">Do you want to speak about AWS and cloud?</li>
    <li class="pl-0 pb-4 md:ml-8">Do you want to speak about programming?</li>
    <li class="pl-0 pb-4 md:ml-8">Your IT solutions need to scale?</li>
    <li class="pl-0 pb-4 md:ml-8">
      Want to have a quick talk over a cup of coffee?
    </li>
  </ul>
  <p class="mt-4 pl-1 pr-12 lg:pr-4">
    I would be more than happy to receive your message.
    <br /><br />You can contact me by filling out the form below or connect with
    me on social media.
  </p>
  <div class="flex flex-col md:flex-row mb-4 mt-6">
    <div class="flex-none pr-12 align-center justify-center">
      <contact-form></contact-form>
    </div>
    <div class="basis-1/2 shrink pl-4">
      <ul>
        <li class="my-4">
          <font-awesome-icon :icon="['fab', 'linkedin']" />
          <a class="ml-2" href="https://www.linkedin.com/in/prazian/"
            >linkedin.com/in/prazian</a
          >
        </li>
        <li class="my-4">
          <font-awesome-icon :icon="['fab', 'github']" />
          <a class="ml-2" href="https://github.com/prazian"
            >github.com/prazian</a
          >
        </li>
        <li class="my-4">
          <font-awesome-icon :icon="['fab', 'twitter']" />
          <a class="ml-2" href="https://twitter.com/pooyan_razian"
            >twitter.com/pooyan_razian</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ContactForm from "@/components/ContactForm.vue";

@Options({
  components: {
    ContactForm,
  },
})
export default class Certificates extends Vue {}
</script>
